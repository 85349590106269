import type { Result as GetResult } from '~~/src/server/api/[site]/content/form/[handle].get';
import { useCachedGet } from '../genericFetchers';
import type { Result as PostResult } from '~~/src/server/api/[site]/content/form/[handle].post';

export async function useLoadForm(typeHandle: string) {
  const site = useSiteIdent();
  return useCachedGet<GetResult>(`/api/${site}/content/form/${typeHandle}`);
}

export async function useSubmitForm(
  typeHandle: string,
  formData: any,
  googleCaptchaV3Token: string,
  site: string,
): Promise<PostResult> {
  return useFetch(`/api/${site}/content/form/${typeHandle}`, {
    query: useRoute().query,
    method: 'post',
    body: {
      formData,
      googleCaptchaV3Token,
    },
  });
}
